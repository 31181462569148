<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <!-- <p>Drop us Message for any Query</p> -->
        </div>
    </div>
</div>
<section class="features-area pb-70 bg-f4f6fc" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container mt-5 mb-2">
        <div class="row">
            <div class="col-lg-4 col-md-4 mb-12">
                <p class="news-title">
                    <a href="blog/clinical-practice-guideline" >Clinical Practice Guideline for the Diagnosis, Evaluation, and Treatment of Attention Deficit/Hyperactivity Disorder in Children and Adolescents Pediatrics;Dr. SamirDalwai; 2019;144(4):E20192528 </a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-12">
                <p class="news-title">
                    <a href="blog/identification-evaluation-management" >Identification, Evaluation, and Management of Children With Autism Spectrum Disorder; Dr. Samir Dalwai; 2020;145(1):e20193447 </a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-12">
                <p class="news-title">
                    <a href="/blog/shift-to-online-teaching-during-covid-19">Shift to Online Teaching during Covid-19 – Early Challenges and Immediate Measures undertaken by teachers in India; Manish Rammohan Garg, Deepti Kanade-Modak, Sandhya Kulkarni, Kamini Prakash Rege, Samir Hasan Dalwai</a>
                </p>
            </div>

            <!-- <div class="col-lg-4 col-md-4 mb-12">
                <p class="news-title">
                    <a href="http://www.apjpch.com/pdfs/20119WRe232909.pdf" target="_blank">Dr. Samir HDalwai, Dr. Manish Garg. April 2021.‘COVID-19- Birth of a New Dawn’. COMHAD Newsletter (Commonwealth Association for Health & Disability), p. 8,9</a>
                </p>
            </div> -->
                
            

        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>
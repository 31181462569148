import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dr-samir-hdalwai-dr-manish-garg',
  templateUrl: './dr-samir-hdalwai-dr-manish-garg.component.html',
  styleUrls: ['./dr-samir-hdalwai-dr-manish-garg.component.scss']
})
export class DrSamirHdalwaiDrManishGargComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

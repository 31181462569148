<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container">
        <div class="section-title">
            <h2 class="missions-h2">Services</h2>
        </div>
        <!--new servcies start-->
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".1s">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>Neurodevelopmental & Behavioural Disorders</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".2s">
                    <div class="icon">
                        <a href="/conditions/asd" class="icon-service"><i class='bx bx-cog'></i></a>
                    </div>
                    <h3><a href="/conditions/asd" class="icon-link">Autism Spectrum Disorder (ASD)</a></h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".3s">
                    <div class="icon">
                        <a href="/conditions/autism" class="icon-service"><i class='bx bxs-bell-ring'></i></a>
                    </div>
                    <h3><a href="/conditions/autism" class="icon-link">Attention Deficit Hyperactivity Disorder (ADHD)</a></h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Specific Learning Disorder (SLD)</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".5s">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Intellectual Disability</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Muscular Dystrophy</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".7s">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Cerebral Palsy (CP)</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".8s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Speech and Language Disorder</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".9s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Developmental Delays</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".10s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Behavioural Concerns</h3>
                </div>
            </div>
        
         <!--new servcies end-->
        
            <!-- <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>Cerebral Palsy</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>Intellectual Disability</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Autism Spectrum Disorders</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Learning Disability<br> (Dyslexia, Dysgraphia, and Dyscalculia)</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Attention Deficit Hyperactivity Disorder</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Speech and Language Disorders</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Genetic Syndromes such as Down Syndrome and others</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Neuro-motor Impairment</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Vision and Hearing Impairment</h3>
                </div>
            </div> -->
        </div>
    </div>
</section>
<section class="faq-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/e-nable-light-nwcdc.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/e-nable-light-nwcdc-1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="faq-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h4><strong><span>NHD offers services</span> to schools, corporates and community through:</strong></h4>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    School Based Intervention Programs <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    School Based Intervention Programs for Counselling and Developmental Therapies customized as per the needs of the schools.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Teacher Development Programs  <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    Teacher Development Programs for educational institution-wise training of teachers on developmental concerns seen in schools, creating and developing the school’s capacities on management of developmental concerns to effectively achieve academic and personal goals of students.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Parenting sessions <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    Parenting sessions, Child Enrichment Programs and workshops for educational institutes, corporates, school managements on the topics of child development, emotional development of children, developmental disorders and management
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/services-image/service5.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>




import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public show: Boolean;
  public platformLocation: any;
  constructor(private router: Router) {}

  ngOnInit() {
    this.show = true;
    this.router.events.subscribe(val => {
      const currentPage = this.router.url; // Current page route
      if (currentPage === "/help-yourself") {
        this.show = false;
      }
    });
  }

}

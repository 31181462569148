<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 class="main-page-head">Attention Deficit Hyperactivity Disorder</h1>
        </div>
    </div>
</div>
<section class="features-card-section pt-70 pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp text-justify" data-wow-delay=".1s" style="margin-top: 50px;">
                <p>ADHD is a prevalent neurodevelopmental disorder that affects children, typically diagnosed in childhood but can be missed and have significant effects in adulthood in terms of work and personal relationships. Children with ADHD have trouble paying attention, controlling impulsive behaviors, or are hyperactive at most times with poor sitting tolerance and inability to complete tasks.</p>
                <p>ADHD affects males and females with males being predominantly hyperactive and females being predominantly inattentive. ADHD can be diagnosed only after the child has completed 4 years of age.</p>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp mb-3 text-justify" data-wow-delay=".1s">
                <div class="about-image">
                    <img src="../../../../assets/img/home-vactor.jpg" alt="image">
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="features-card-section pt-5 pb-20 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                <h2 class="page-hed-h5">Signs and Symptoms</h2>
                <p>It is generally noted that at multiple times that children are hyperactive and lack attention.  However, when these symptoms affect the functional performance of the child and such behaviors are noted in 2 or more settings ( Home, school, play, etc ) then the child is suspected to have ADHD.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".3s">
                <p>A child with ADHD might present with the following symptoms:</p>
                <h2 class="page-hed-h5">Inattention</h2>
                <ul>
                    <li> Demonstrates difficulty maintaining focus on specific tasks or activities.</li>
                    <li>Tends to disregard spoken instructions and is frequently unable to complete assignments, duties, or household tasks.</li>
                    <li>Displays poor organizational skills and has trouble planning and prioritizing tasks.</li>
                    <li>Shows reluctance towards engaging in activities that require prolonged mental efforts, such as homework or schoolwork.</li>
                    <li>Regularly misplaces essential items needed for tasks or activities, such as school materials, tools, or personal possessions.</li>
                    <li>Easily distracted and often forgetful in daily routines.</li>
                </ul>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".4s">
                <h2 class="page-hed-h5">Hyperactivity and Impulsivity</h2>
                <ul>
                    <li>Frequently displays fidgeting behaviors such as tapping hands or feet or squirming in their seat.</li>
                    <li>Tends to leave their seat in situations where remaining seated is expected.</li>
                    <li>Demonstrates excessive energy and a tendency to run or climb in inappropriate situations.</li>
                    <li>Difficulty engaging in leisure activities in a quiet and calm manner.</li>
                    <li>Demonstrates a constant state of activity and restlessness.</li>
                    <li>Talks excessively and has a tendency to blurt out answers before questions have been completed.</li>
                    <li>Demonstrates difficulty waiting their turn and often interrupts or intrudes on others during conversations or activities.</li>
                </ul>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".5s">
                <h2 class="page-hed-h5">Types of ADHD</h2>
                <ul class="serv-list">
                    <li>Predominantly Inattentive Presentation</li>
                    <li>Predominantly Hyperactive-Impulsive Presentation</li>
                    <li>Combined Presentation</li>
                </ul>
            </div>   
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".6s">
                <h2 class="page-hed-h5">What Causes ADHD?</h2>
                <p>There is no definite known cause for ADHD yet,  but current research shows that genetics plays an important role. Recent studies link genetic factors with ADHD.</p>
                <p>In addition to genetics, there could be a possible association with the following:</p>
                <ul class="serv-list">
                    <li>Brain injury</li>
                    <li>Exposure to environmental risks (e.g., lead) during pregnancy or at a young age</li>
                    <li>Alcohol and tobacco use during pregnancy</li>
                    <li>Premature delivery</li>
                    <li>Low birth weight </li>
                </ul>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <p>Contrary to popular belief, research has not found a direct correlation between ADHD and factors such as consuming excessive amounts of sugar, excessive television viewing, poor parenting, or socio-economic or environmental factors such as poverty or family turmoil. While these factors may exacerbate symptoms in certain individuals, there is insufficient evidence to support the claim that they are the primary causes of ADHD.</p>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <h2 class="page-hed-h5">How can we diagnose ADHD?</h2>
                <p>Diagnosing ADHD in children is a multi-step process that cannot be determined by a single test. It is important to note that other conditions such as anxiety, depression, sleep disorders, and certain learning disabilities can exhibit similar symptoms and may coexist with ADHD. A pediatrician or developmental pediatrician is a key professional in the evaluation process. In order to ensure an accurate diagnosis, it is highly recommended to undergo a thorough medical examination with an expert, including hearing and vision tests to rule out other potential causes of symptoms similar to ADHD.</p>
                <p>The process of diagnosing ADHD typically involves the use of a standardized symptom checklist and obtaining a detailed history of the child's behavior and symptoms from various sources such as the parents, teachers, and the child themselves.</p>
                <p>Multiple psychological tools are available which can be used for the diagnosis of ADHD and also its co-morbidities.</p>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <h2 class="page-hed-h5">What are treatment options available? </h2>
                <p>In most cases, ADHD is best treated with a combination of behavior therapy and medication. The earlier the intervention the better the outcome.</p>
                <p>For preschool-aged children (4-5 years old) with ADHD, behavior therapy, specifically, parent training, is recommended as the initial approach before considering medication options. For children over the age of 6, a combination of medication and behavior therapy, including parent and child training, is suggested.</p>
                <p>However, the line of intervention is also based on individual concerns and can never be a blanket therapy. Also, intervention needs to be regularly monitored by the Developmental Pediatrician, and from time to time modifications may be needed.</p>
            </div>
            
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <h2 class="page-hed-h5">What are the simple measures that can be implemented at home to help children with ADHD?</h2>
                <ul>
                    <li>Scheduling the day</li>
                    <li>Limiting screen time and avoiding violent content on screen</li>
                    <li>Sleep Hygiene</li>
                    <li>Healthy dietary habits: eating fruits, vegetables, whole grains, pulses and avoiding packed preservative food</li>
                    <li>Physical exercise, yoga, extracurricular sports activities</li>
                    <li>Breaking tasks into smaller parts</li>
                    <li>Helping with organization</li>
                </ul>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <h2 class="page-hed-h5">Developmental Programs for ADHD in Mumbai at NHCDC</h2>
                <p>At <a href="https://newhorizonscdc.in/" class="link-page">New Hozions Child Development Center,</a> every child gets an assessment and diagnosis by a Developmental Pediatrician, who then outlines a customized intervention plan that is based on the child’s needs and focuses on outcomes and overall development as part of our programs.</p>
                <p>These programs have been developed by <a href="https://newhorizonscdc.in/team" class="link-page">Dr. Samir Dalwai & team</a> based on decades of developmental experience and research and have benefited thousands of students at our multiple <a href="https://newhorizonscdc.in/contact-us"  class="link-page">child development centers in Mumbai</a> since 2003.</p>
                <p>If you are a parent or caretaker seeking specialized and comprehensive evaluation and care for your child, we would be honored to assist you. Our team of experts is dedicated to providing exceptional pediatric intervention for your child, addressing your concerns, and providing the necessary support to ensure their overall well-being through our programs.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <h2 class="page-hed-h5">Disclaimer</h2>
                <p>The information provided on this website is intended for general informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a qualified pediatrician or other qualified healthcare providers with any questions you may have regarding a medical condition. If you have any concerns about your child's health, please consult with a pediatrician for proper diagnosis and treatment.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_1.gif" class="img-fluid"></div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from "./_helpers/user.guard";
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import {ChattingComponent}  from'./components/common/chatting/chatting.component';
import {ServicesComponent}  from'./components/pages/services/services.component';
import {TeamComponent}  from'./components/pages/team/team.component';
import {GalleryComponent}  from'./components/pages/gallery/gallery.component';
import {BlogComponent}  from'./components/pages/blog/blog.component';
import {TestimonialsComponent}  from'./components/pages/testimonials/testimonials.component';

import { ClinicalPracticeGuidelineComponent } from './components/pages/clinical-practice-guideline/clinical-practice-guideline.component';
import { IdentificationEvaluationManagementComponent } from './components/pages/identification-evaluation-management/identification-evaluation-management.component';
import { ShiftToOnlineTeachingDuringComponent } from './components/pages/shift-to-online-teaching-during/shift-to-online-teaching-during.component';
import { DrSamirHdalwaiDrManishGargComponent } from './components/pages/dr-samir-hdalwai-dr-manish-garg/dr-samir-hdalwai-dr-manish-garg.component';
import {TermsAndConditionsComponent} from './components/pages/terms-and-conditions/terms-and-conditions.component';
import {AutismComponent} from './components/pages/autism/autism.component';
import {AadComponent} from './components/pages/aad/aad.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';


const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'about', component: AboutComponent},
    {path: "cahtting", component: ChattingComponent },
    {path: "services", component: ServicesComponent },
    {path: "contact-us", component: ContactComponent },
    {path: "team", component: TeamComponent },
    {path: "gallery-resource-centre", component: GalleryComponent },
    {path: "blog", component: BlogComponent },
    {path: "testimonials", component: TestimonialsComponent },
    {path: "terms-and-conditions", component: TermsAndConditionsComponent },

    {path: "blog/clinical-practice-guideline", component: ClinicalPracticeGuidelineComponent },
    {path: "blog/identification-evaluation-management", component: IdentificationEvaluationManagementComponent },
    {path: "blog/shift-to-online-teaching-during-covid-19", component: ShiftToOnlineTeachingDuringComponent },
    {path: "blog/dr-samir-hdalwai-dr-manish-garg", component: DrSamirHdalwaiDrManishGargComponent },
    {path: "conditions/autism", component: AutismComponent },
    {path: "conditions/asd", component: AadComponent },
    {path: "privacy-policy", component: PrivacyPolicyComponent },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

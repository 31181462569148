import { Component, OnInit } from '@angular/core';
import { QuestionService } from "../../../question.service";
import * as $ from "jquery";
declare var Highcharts: any;
import { Title, Meta }     from '@angular/platform-browser';



@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {
  public stats: any;
  public fullDetails: any;
  public sateDetails: any;
  public selectedTarget: any;
  public loading: Boolean = false;
  constructor(public questionService: QuestionService,private title:Title, private meta:Meta) {}
  indianStats: any;
  
  ngOnInit() {
    this.title.setTitle('New Horizons Child Development Centre');
    this.meta.updateTag({ name: 'keywords', content: 'New Horizons Child Development Centre, Autism, Autism Spectrum Disorder, ADHD, Speech Therapy, Downs Syndrome, Cerebral Palsy, Child Development, Developmental Pediatrician, Dyslexia, Learning Disability’, Language Disorder, Dr Samir Dalwai, Child Therapy, Child Care, Child Therapy, Child Growth, Paediatrician, Language Disorder, Neuto Motor Impairment, Disorders, Child Counselling, New Horizons, CDC, Special Child, Disabled Children, Disabled Child.' });
		this.meta.updateTag({ name: 'description', content: 'New Horizons Child Development Centre is one of the largest multi-disciplinary, comprehensive and integrated child development centres in India. Founded in 2003, with currently 5 centres across Mumbai, NHCDC offers child developmental services under one roof through an inter-disciplinary team headed by Renowned Developmental Paediatricians.' });

    this.sateDetails = null;
    this.stats = {};
    this.fullDetails = {};
    this.selectedTarget = null;
    // this.questionService.getStat().subscribe(stats => {
    //   this.stats = stats;
    //   //console.log(stats);
    // });

    this.indianStats = {
      total: { title: "Total Positive cases", value: 10, description: "" },
      recoverd: { title: "Recovered", value: 10, description: "" },
      deaths: { title: "Deaths", value: 10, description: "" }
    };

    this.indianStats.total.value = 0;

    //    console.log(data);
    this.loading = true;
    // this.questionService.getStatewiseStat().subscribe(stats => {
    //   this.loading = false;
    //   this.fullDetails = stats;
    //   //      console.log(this.fullDetails.statewise);
    //   let data1 = this.fullDetails.statewise.map(item => {
    //     this.indianStats.total.value += item.active;
    //     //  console.log([item.state, item.active]);
    //     return [item.state.toLowerCase(), parseInt(item.active)];
    //   });
    //   data1.shift();
    //   // console.log(data1);
    //   // Create the chart
    //   // console.log(data1);
    //   var chart = Highcharts.mapChart("container", {
    //     chart: {
    //       map: "countries/in/custom/in-all-disputed"
    //     },

    //     title: {
    //       text: "Covid 19 in India"
    //     },
    //     plotOptions: {
    //       map: {
    //         selected: true,
    //         events: {
    //           mouseOver: event => {
    //             console.log(event);
    //           },
    //           click: event => {
    //             // console.log(event.target);
    //             var selectedPoints = chart.getSelectedPoints();

    //             console.log(chart);
    //             // $(event.target).css({ fill: "#ABF2BB" });
    //             //console.log("click", event.point.properties["hc-key"]);
    //             this.showLeftDetails(event.point.properties["hc-key"]);
    //           }
    //         }
    //       },
    //       mappoint: {
    //         events: {
    //           mouseOver: function(event) {
    //             console.log(event);
    //           },
    //           click: function(event) {
    //             console.log("click", event);
    //           }
    //         }
    //       }
    //     },
    //     subtitle: {
    //       text: ""
    //     },
    //     exporting: false,
    //     mapNavigation: {
    //       enabled: true,
    //       enableMouseWheelZoom: false,
    //       buttonOptions: {
    //         verticalAlign: "bottom"
    //       }
    //     },

    //     colorAxis: {
    //       min: 0
    //     },
    //     tooltip: {
    //       backgroundColor: "darkgray"
    //     },
    //     series: [
    //       {
    //         data: data1,
    //         name: "Coronavirous Infection",
    //         allowPointSelect: true,
    //         cursor: "pointer",
    //         states: {
    //           hover: {
    //             color: "#BADA55"
    //           },
    //           select: {
    //             color: "#BADA55",
    //             borderColor: "black",
    //             dashStyle: "dot"
    //           }
    //         },
    //         dataLabels: {
    //           enabled: true,
    //           overflow: "allow",
    //           allowOverlap: true,
    //           useHTML: true,
    //           formatter: function() {
    //             let name = this.point.value;
    //             return "";
    //           }
    //         }
    //       }
    //     ]
    //   });
    //   this.showLeftDetails("Maharashtra");
    // });
  }

  // showLeftDetails(key) {
  //   let details = this.fullDetails.statewise.filter(item => {
  //     return item.state.toLowerCase() == key.toLowerCase();
  //   });
  //   if (details && details.length > 0) {
  //     console.log(details);
  //     this.sateDetails = details[0];
  //   } else {
  //     console.log(details);
  //     this.sateDetails = null;
  //   }
  // }
}



<div class="main-banner main-banner-two desktop">
    <div class="container-fluid p-0 m-0">
        <div class="row">
            
            <div class="col-lg-12 col-md-12 p-0">
                <div class="banner-image-slides owl-carousel owl-theme">
                    <!-- <a href="javascript:void(0)" class="default-btn banner-image banner-slider-bg1"></a> -->
                    <a routerLink="/help-yourself" class="default-btn banner-image banner-slider-bg3"></a>
                    <a href="javascript:void(0)" class="default-btn banner-image banner-slider-bg2"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="features-card-section pt-70 pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp " data-wow-delay=".2s" style="margin-top: 25px;">
                <h2 class="wel-head">Welcome To New Horizons Child Development Center</h2>
                <p class="text-justify mt-3 mb-5">New Horizons Child Development Centre, introduced for the first time in India the concept and practice of self-funded inter-disciplinary, measurable and outcome based rehabilitation where each child gets individual evaluation and intervention in a comprehensive, scientifically planned and documented manner.</p>
                <a class="btn-safety-orange" href="/about">Read More</a>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp mb-3 text-justify" data-wow-delay=".4s">
                <div class="about-image">
                    <img src="../../../../assets/img/home-vactor.jpg" alt="image">
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="features-area pt-100 pb-70 bg-f4f6fc" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container">
        <div class="section-title">
            <h2 class="missions-h2">Services</h2>
        </div>
        <!--new servcies start-->
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".1s">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>Neurodevelopmental & Behavioural Disorders</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".2s">
                    <div class="icon">
                        <a href="/conditions/asd" class="icon-service"><i class='bx bx-cog'></i></a>
                    </div>
                    <h3><a href="/conditions/asd" class="icon-link">Autism Spectrum Disorder (ASD)</a></h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".3s">
                    <div class="icon">
                        <a href="/conditions/autism" class="icon-service"><i class='bx bxs-bell-ring'></i></a>
                    </div>
                    <h3><a href="/conditions/autism" class="icon-link">Attention Deficit Hyperactivity Disorder (ADHD)</a></h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Specific Learning Disorder (SLD)</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".5s">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Intellectual Disability</h3>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Muscular Dystrophy</h3>

                </div>
            </div>
        </div>
        <!--new servcies end-->
    </div>
    <div class="text-center">
        <a class="btn-safety-orange" href="/services">Read More</a>
    </div>
</section>

<section class="video-presentation-area ptb-100" style="background-image: url(../../assets/img/counter-bg.jpg);">
    <div class="container">
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInUp funfact-style-3dc3dc" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">+</span></h3>
                        <p>Team Members</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInUp funfact-style-aec536" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="17 ">00</span><span class="sign-icon">+</span></h3>
                        <p>Years Experience</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInUp funfact-style-fecb4c" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="73,000">00</span><span class="sign-icon">+</span></h3>
                        <p>Sessions Every Year</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow fadeInUp " data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two funfact-style-f2757d">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="5000">00</span><span class="sign-icon">+</span></h3>
                        <p>Lives touched till date</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>

<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image wow fadeInLeft" data-wow-delay=".3s">
                    <img src="assets/img/dr-samir.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Dr. Samir Hasan Dalwai</span>
                    <h5>MD, DCH, DNB, FCPS, FIAP, LLB,</h5>
                    <h5>Developmental Behavioural Pediatrician,</h5>
                    <h5>Founder/Director: New Horizons Child Development Centre, India.</h5>
                    <p>Dr. Samir Hasan Dalwai also serves as Developmental Pediatrician for New Horizons Child Development Centre® in Mumbai, India since 2003. His clinical attachments include Hinduja HealthCare Hospital and Nanavati SuperSpecialty Hospital. He has been awarded the Government of India's Rajiv Gandhi Manav Sewa Award for Services to Children in 2012</p>
                    <p>New Horizons Child Development Centre lead by Dr. Samir has an interdisciplinary team of 110 Developmental Pediatricians, Clinical and Counseling Psychologists, Occupational and Physical Therapists, Speech and Language Therapists, Remedial Educators and Nutritionists, with five centers & providing Online services too. </p>
                    <div class="btn-box">
                        <a href="/team" class="btn-safety-orange">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-area pt-100 pb-70 bg-testimonial" >
    <div class="container">
        <div class="section-title">
            <h2>Reviews</h2>
        </div>
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item">
                <div class="testimonials-desc">
                    <p>“Shaurya was seven months old when we took admission. All his milestones were delayed according to his age. Slowly and gradually with intervention he started to hold his head…… We want to continue with the sessions we are finding result in Shaurya. I thank all the therapists and special thanks to Dr SamirDalwai for his guidance.”</p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Mrs Kasturi V. Gharaniya</h3>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                
                <div class="testimonials-desc">
                    <p>“NHD have not only given us hope but also have shown the way where our child can again lead a normal life. The training program, developmental program and the overall teaching stuff is very professional as well as very emotional towards our and our child’s needs.”<br>Thanks </p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Jiyansh Jain, Goregaon East</h3>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                
                <div class="testimonials-desc">
                    <p>“Before coming to NHCDC, my child had zero eye contact, no communication and preferred to be in his own world. He also faced sensory issues and hyperactivity. After meeting Dr. Samir Dalwai and with last 5 months of therapy, we see a lot of difference in our child. ” </p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Payal Waghela (Dhruvin Waghela ), Goregaon West</h3>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
               
                <div class="testimonials-desc">
                    <p>“We have seen significant improvement in Ivaan’s interaction and understanding since we have joined NHDP. His concentration and sitting tolerance has also gotten better. He has started responding in school as well. The activities done by the therapist are helping in his overall development. ” </p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                </div>
                <div class="client-info">
                    <h3>Ivaan Shah, Khar</h3>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="testimonials-desc">
                    <p>“What NHD Centre and teachers are doing is really a great job. They are hope for unable children and their parents. We are very grateful to them.”</p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="testimonials-desc">
                    <p>“After sessions when the demonstration is given to us, it makes us understand exactly how and what is supposed to be done at home. Most of our questions get resolved at the earliest and our perspective of making comparisons with other children have stopped. It was a great pleasurable experience ”</p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Rutuja Uparkar, Dadar</h3>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="testimonials-desc">
                    <p>“Harsh has improved a lot, he is very confident in all the areas, his handwriting has improved a lot and he loves to try new things and he is very independent only because of your guidance and hard work and he loves to spend time with his Didi. Thank you so many ma’am for all your love to Harsh and Dr Samir, your suggestions are always helpful. ”</p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Harsh Wadhwani. Goregaon East</h3>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="testimonials-desc">
                    <p>“It’s been a year and half we have been with NHD and I have seen and enormous change in my son. You guys have always been there whenever that extra support was required in regards to new strategies and flexibility in approach towards my sons needs. ”</p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3>Prajakta Kamble, Bhandup</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="footer-gif"><img  class="img-fluid" src="../../../../assets/img/gif/panel_5.gif"></div>
<!-- 
<section class="features-card-section pt-100 pb-70 bg-1e64a2">
    <div class="container">
        <div class="section-title">
            <h2 class="text-white">Latest Updates</h2>
        </div>
        <div class="row">
        </div>
    </div>
</section> -->

<!-- <div id="myModal" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="btn btn-default text-right" data-dismiss="modal" style="padding: 10px 5px;"><span class="modal-close">X</span></button>
            <img src="assets/img/we-are-relocate-1.jpg" class="popup-lg">
            <img src="assets/img/we-are-relocate-2.jpg" class="popup-sm">
            
        </div>
    </div>
</div> -->


import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private title:Title, private meta:Meta) { }

  ngOnInit() {
    this.title.setTitle('Gallery & Resource Centre - New Horizons Child Development Centre');
    this.meta.updateTag({ name: 'keywords', content: 'New Horizons Child Development Centre, Autism, Autism Spectrum Disorder, ADHD, Speech Therapy, Downs Syndrome, Cerebral Palsy, Child Development, Developmental Pediatrician, Dyslexia, Learning Disability’, Language Disorder, Dr Samir Dalwai, Child Therapy, Child Care, Child Therapy, Child Growth, Paediatrician, Language Disorder, Neuto Motor Impairment, Disorders, Child Counselling, New Horizons, CDC, Special Child, Disabled Children, Disabled Child.' });
		this.meta.updateTag({ name: 'description', content: 'New Horizons Child Development Centre is one of the largest multi-disciplinary, comprehensive and integrated child development centres in India. Founded in 2003, with currently 5 centres across Mumbai, NHCDC offers child developmental services under one roof through an inter-disciplinary team headed by Renowned Developmental Paediatricians.' });
  }

}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Gallery &amp; Resource Centre</h2>
        </div>
    </div>
</div>
<section>
    <div class="container mt-5 mb-2">
        <div class="row">
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/soha-didi.jpg" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://www.thehealthsite.com/news/hinduja-healthcare-surgical-inaugurates-new-pediatric-ward-with-advanced-facilities-d0418-565110/amp/" target="_blank">World Health Day: How well-equipped are we for surgical strike on coronavirus?</a>
                </h6>
                <p >
                    <a href="https://www.thehealthsite.com/news/hinduja-healthcare-surgical-inaugurates-new-pediatric-ward-with-advanced-facilities-d0418-565110/amp/" target="_blank">m.freepressjournal.in</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/soha-ali.jpg" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://m.freepressjournal.in/article/cmcm/mumbai-differently-able-kids-to-be-treated-on-par/ac457412-9401-4be3-835a-40bad42bf7bb" target="_blank">Mumbai: Differently-able kids to be treated on par</a>
                </h6>
                <p>
                    <a href="https://m.freepressjournal.in/article/cmcm/mumbai-differently-able-kids-to-be-treated-on-par/ac457412-9401-4be3-835a-40bad42bf7bb" target="_blank">m.freepressjournal.in</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/dr-parik.jpg" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://m.freepressjournal.in/article/analysis/world-health-day-how-well-equipped-are-we-for-surgical-strike-on-coronavirus/34e7f15e-dff3-4cba-814b-a578ff957c9f" target="_blank">Hinduja healthcare surgical inaugurates new pediatric ward with advanced facilities</a>
                </h6>
                <p>
                  <a href="https://m.freepressjournal.in/article/analysis/world-health-day-how-well-equipped-are-we-for-surgical-strike-on-coronavirus/34e7f15e-dff3-4cba-814b-a578ff957c9f" target="_blank">www.thehealthsite.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/The-kids-are.jpg" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://www.mid-day.com/mumbai/mumbai-news/article/mumbai-children-who-had-high-exposure-to-mobile-phones-and-tv-due-to-covid-19-are-struggling-with-emotional-behavioural-issues-say-experts-23269867" target="_blank">Mumbai: How increased screen time effects child’s emotional and behavioural wellness
                    </a>
                </h6>
                <p>
                  <a href="https://www.mid-day.com/mumbai/mumbai-news/article/mumbai-children-who-had-high-exposure-to-mobile-phones-and-tv-due-to-covid-19-are-struggling-with-emotional-behavioural-issues-say-experts-23269867" target="_blank">www.mid-day.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/newspatrolling-logo.png" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://newspatrolling.com/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">Excessive Screen Time, Anxiety Caused Developmental & Behavioural problems In Children amidst Lockdown
                    </a>
                </h6>
                <p>
                  <a href="https://newspatrolling.com/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">newspatrolling.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/phone-addiction.jpg" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://www.deccanherald.com/national/west/increased-screen-time-affecting-children-experts-1190446.html" target="_blank">Increased screen time affecting children: Experts
                    </a>
                </h6>
                <p>
                  <a href="https://www.deccanherald.com/national/west/increased-screen-time-affecting-children-experts-1190446.html" target="_blank">www.deccanherald.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/welthi.png" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://welthi.com/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown" target="_blank">Excessive Screen Time, Anxiety Caused Developmental & Behavioural problems in Children amidst Lockdown
                    </a>
                </h6>
                <p>
                  <a href="https://welthi.com/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown" target="_blank">welthi.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/Media-Bulletins-Logo.png" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://mediabulletins.com/health/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">Excessive Screen Time, Anxiety Caused Developmental & Behavioural problems in Children amidst Lockdown
                    </a>
                </h6>
                <p>
                  <a href="https://mediabulletins.com/health/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">mediabulletins.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/cropped-logo-new.png" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://onlinemediacafe.com/business/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">Excessive Screen Time, Anxiety Caused Developmental & Behavioural problems in Children amidst Lockdown
                    </a>
                </h6>
                <p>
                  <a href="https://onlinemediacafe.com/business/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">onlinemediacafe.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/cropped-website_logo_transparent_background.png" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://contentmediasolution.com/health/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">Excessive Screen Time, Anxiety Caused Developmental & Behavioural problems In Children amidst Lockdown
                    </a>
                </h6>
                <p>
                  <a href="https://contentmediasolution.com/health/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">contentmediasolution.com</a>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 mb-4">
                <img src="../../../../assets/img/biznewsdesk.png" class="img-fluid">
                <h6 class="news-title">
                    <a href="https://biznewsdesk.com/health/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">Excessive Screen Time, Anxiety Caused Developmental & Behavioural problems In Children amidst Lockdown
                    </a>
                </h6>
                <p>
                  <a href="https://biznewsdesk.com/health/excessive-screen-time-anxiety-caused-developmental-behavioural-problems-in-children-amidst-lockdown/" target="_blank">smartbusinesnews.com</a>
                </p>
            </div>

            
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Injectable({
  providedIn: "root"
})
export class QuestionService {
  constructor(private http: HttpClient) {}
  public API_BASE_URL = "https://diy.health/diy-api/public/index.php/api/";
  // public API_BASE_URL = "http://covid.newhorizonscdc.in/diy-api/public/api/";
  //public API_BASE_URL = "http://localhost:8000/api/";

  getQuestions(): Observable<any> {
    return this.http.get<any[]>(this.API_BASE_URL + "getQuestions");
  }

  signup(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json"
    };

    return this.http.post<any>(this.API_BASE_URL + "signup", details, {
      headers
    });
  }

  sendOTP(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json"
    };

    return this.http.post<any>(this.API_BASE_URL + "sendOTP", details, {
      headers
    });
  }

  otpsignup(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json"
    };

    return this.http.post<any>(this.API_BASE_URL + "verifyOTP", details, {
      headers
    });
  }

  login(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json"
    };

    return this.http.post<any>(this.API_BASE_URL + "login", details, {
      headers
    });
  }

  save(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json"
    };
    if (typeof localStorage.diy_api_tokne != "undefined") {
      headers["Authorization"] = localStorage.diy_api_tokne;
    }

    return this.http.post<any>(this.API_BASE_URL + "saveAnswers", details, {
      headers
    });
  }

  changePassword(details) {
    const headers = new HttpHeaders().set(
      "Authorization",
      localStorage.diy_api_tokne
    );
    return this.http.post<any>(this.API_BASE_URL + "changePassword", details, {
      headers
    });
  }

  getUserDetails() {
    const headers = new HttpHeaders().set(
      "Authorization",
      localStorage.diy_api_tokne
    );
    return this.http.get<any>(this.API_BASE_URL + "getUserDetails", {
      headers
    });
  }

  updateProfile(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.diy_api_tokne
    };

    return this.http.post<any>(this.API_BASE_URL + "updateProfile", details, {
      headers
    });
  }
  saveBookin(details) {
    console.log(details);
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.diy_api_tokne
    };

    return this.http.post<any>(this.API_BASE_URL + "saveBookin", details, {
      headers
    });
  }

  getOneAnswer(id) {
    return this.http.get<any>(this.API_BASE_URL + "getanswer/" + id);
  }

  getStat() {
    return this.http.get<any>("https://api.covid19india.org/data.json");
  }

  getStatewiseStat() {
    return this.http.get<any>("https://api.covid19india.org/data.json");
  }

  getCenterNearMe(cords) {
    return this.http.get<any>(this.API_BASE_URL + "getCenterNearMe");
  }

  getBookingDetails(id) {
    const headers = new HttpHeaders().set(
      "Authorization",
      localStorage.diy_api_tokne
    );
    return this.http.get<any>(this.API_BASE_URL + "getBookingDetails/" + id, {
      headers
    });
  }

  getAppointments() {
    const headers = new HttpHeaders().set(
      "Authorization",
      localStorage.diy_api_tokne
    );
    return this.http.get<any>(this.API_BASE_URL + "getAppointments", {
      headers
    });
  }
  getMyTests() {
    const headers = new HttpHeaders().set(
      "Authorization",
      localStorage.diy_api_tokne
    );

    return this.http.get<any>(this.API_BASE_URL + "getMyTests", { headers });
  }

  // getStat() {
  //   return this.http.get<any>(
  //     "https://coronavirus-19-api.herokuapp.com/countries/india"
  //   );
  // }
}

<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            
        </div>
    </div>
</div> -->


<section class="faq-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <h3>Identification, Evaluation, and Management of Children With Autism Spectrum Disorder</h3>
                <p>Hyman SL, Levy SE, Myers SM, AAP Council On Children With Disabilities</p>
                <p>Section On Developmental And Behavioral Pediatrics. 2020;145(1):e20193447</p>
                <p><strong>Background</strong> ASD is a common neurodevelopmental disorder (NDD) with significant care needs. With an estimated prevalence of approximately 1.7%, only a minority is identified before 3 years and almost half are diagnosed > 6 years of age, especially those with minor symptoms and better cognitive and language development. </p>

                <p><strong>Methodology:</strong>  Clinical update by American Academy of Pediatrics (AAP) focusing on evaluation and treatment of ASD in light of emerging research.</p>
                <p><strong>Screening and diagnosis</strong></p>

                <ul>
                    <li>
                        As per DSM-5 diagnostic criteria, all 3 symptoms of deficits in social communication and social interaction need to be present with 2 of 4 symptoms in restrictive and repetitive behaviors, along with impairment of function in family, peer and school settings demonstrated.
                    </li>

                    <li>
                        Universal screening through developmental surveillance at 9, 18, 30 months and standardized autism specific screening tests at 18 and 24 months of age helps identify at-risk children.
                    </li>    

                    <li>
                        Formal assessment of language and cognitive abilities is important for ASD diagnostic process.
                    </li>

                    <li><strong>Comorbidities</strong> include sleep disorders, seizures, intellectual disability, ADHD, other developmental disorders, anxiety, mood disorders and behavioral disorders like food refusal, self-injury and aggression. These impact social and functional impairment, intervention selection, and prognosis.</li>
                    <li>
                        Currently, no diagnostic laboratory tests or biomarkers have been approved.
                    </li>
                </ul>
                <p><strong>MEDICAL EVALUATION</strong></p>
                <div class="faq-image">
                    <img src="assets/img/clinical-practice-guideline.jpg" alt="image">
                </div>
                

                <p><strong>Interventions:</strong> : Interventions are based on the principles of learning, reinforcement (e.g. applied behavioural analysis), and/or caregiver responsiveness and training (e.g. Parent Mediated Training). Speech and language therapy, motor therapy, and sensory therapies (for hypo or hyper reactivity for sensory input) are used alongside.</p>

                <ul>
                    <li>
                        Psychopharmacological approach targets behavioral and psychiatric symptoms and comorbid conditions not addressed by nonpharmacological therapies or not responsive to them.
                    </li>

                    <li>
                        Diet modification and nutrient fortification (unless the child has specific allergies or deficiencies, respectively), immunological, dance, drama and chiropractic therapy have no evidence of benefit.
                    </li>    

                    
                </ul>
                
                <p><strong>Prognosis:</strong> Difficult to predict. With advancing age social and communication skills improve, but repetitive behaviors persist. More social difficulty in those with language impairment and ID.</p>

                <p><strong>Early treatment <36 months of age has been shown to result in positive outcomes.</strong></p>

                <p><strong>Conclusion:</strong> Early referral and intervention are the key to a good outcome. Standardized screening for ASD at 18 and 24 months of age with ongoing developmental surveillance is essential for early diagnosis as evidence-based interventions for ASD are known to improve functioning</p>
            </div>
            <div class="col-lg-4 col-md-12 mt-25">
                <div class="faq-image">
                    <img src="assets/img/dr-parik-1.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-8 col-md-12 mt-25">
                <p><strong>“Most interventions focus on increasing interaction with the child. It is observed that children with autism have universal impairment in social interaction leading to consequential impairment in social communication sequence and is an important area of further research.”</strong>
                </p>
                <p>Dr Samir Hasan Dalwai, MD, DCH, DNB, FCPS, FIAP, LLB,</p>
                <p>Developmental Behavioural Pediatrician, New Horizons Child Development Centre & Research Foundation, Nanavati and Hinduja Hospitals, Mumbai.</p>
            </div>
        </div>
        <h4 class="mt-25"><strong>With warm regards</strong></h4>
        <div class="row">
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. MANINDERS DHALIWAL<br>
                Editor – Academic Pearls<br>
                pedpearls@gmail.com</p>
            </div>
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. PIYUSH GUPTA<br>
                    IAP NATIONAL PRESIDENT 2021</p>
            </div>
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. REMESH KUMAR<br>
                    IAP PRESIDENT ELECT 2021</p>
            </div>

            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. G.V. BASAVARAJA<br>
                    HON. SECRETARY GEN. 2021 - 22</p>
            </div>
        </div>    
        <div class="mt-25">
            <p><strong>Reference</strong></p>
            <p>Hyman SL, Levy SE, Myers SM, AAP COUNCIL ON CHILDREN WITH DISABILITIES, SECTION ON DEVELOPMENTAL AND BEHAVIORAL PEDIATRICS</p>

            <p>Identification, Evaluation, and Management of Children With AutismSpectrum Disorder. Pediatrics.2020;145(1):e20193447</p>

        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>



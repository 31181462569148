<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <p>Meet Our experts always ready to help you</p>
        </div>
    </div>
</div>
<section class="features-area ptb-100" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image wow fadeInLeft" data-wow-delay=".2s">
                    <img src="assets/img/dr-samir.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12 wow fadeInLeft" data-wow-delay=".3s">
                <div class="app-download-content">
                    <span class="sub-title">Dr. Samir Hasan Dalwai</span>
                    <h5>MD, DCH, DNB, FCPS, FIAP, LLB,</h5>
                    <h5>Developmental Behavioural Pediatrician,</h5>
                    <h5>Founder/Director: New Horizons Child Development Centre, India.</h5>
                    <p>Dr. Samir Hasan Dalwai also serves as Developmental Pediatrician for New Horizons Child Development Centre® in Mumbai, India since 2003. His clinical attachments include Hinduja HealthCare Hospital and Nanavati SuperSpecialty Hospital. He has been awarded the Government of India's Rajiv Gandhi Manav Sewa Award for Services to Children in 2012</p>
                    <p>New Horizons Child Development Centre lead by Dr. Samir has an interdisciplinary team of 110 Developmental Pediatricians, Clinical and Counseling Psychologists, Occupational and Physical Therapists, Speech and Language Therapists, Remedial Educators and Nutritionists, with five centers & providing Online services too. </p>
                </div>
            </div>
            <div class="wow fadeInup" data-wow-delay=".3s">
            <p>Dr. Dalwai is also the Founder of the New Horizons Institute of Education and Research and New Horizons Health and Research Foundation.</p>
            <p>Dr. Dalwai has presented numerous papers at national and international conferences, as well as contributed chapters to journals and textbooks. He has trained paediatricians all across India. He is the National Joint Secretary of the Indian Academy of Pediatrics.</p>
            <p>Dr Dalwai’s work centres around evaluation, diagnosis, intervention and counselling for children with</p>
            <ul>
                <li>developmental concerns (delayed development, delayed speech, motor disorders, spasticity, intellectual disabilities)</li>
                <li>syndromic concerns (Down Syndrome, Fragile X Syndrome, Prader Willi Syndrome, Tuberous Sclerosis, Angelman Syndrome, Rett Syndrome)</li>
                <li>behavioural concerns (hyperactivity, impulsivity, inattention, aggression, bed wetting and elimination disorders, eating disorders, sleep disturbances)</li>
                <li>academic concerns (reading and writing difficulties, handwriting concerns, learning disabilities- dyslexia, dysgraphia, dyscalculia, poor school performance)</li>
                <li>emotional and mood disturbances (anxiety, withdrawal, depression, issues of adolescence, suicidal ideation).</li>
            </ul>
            <p>At New Horizons Child Development Centre, Dr Dalwai and his team makes specialised, holistic, outcome-oriented, customised and individualised online intervention programs to help families and children with Autism Spectrum Disorder(ASD), Attention Deficit Hyperactivity Disorder (ADHD), Learning Disability (LD), Cerebral Palsy (CP), Intellectual Disability (ID), Genetic and Syndromic Disorders, Anxiety and Depression and Adolescents with various concerns.</p>
            </div>
        </div>
    </div>
</section>
<div class="container"><div class="border-b"></div></div>
<section class="features-area pt-5 pb-5" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div  data-wow-delay=".2s" class="app-download-image wow fadeInLeft" style="visibility: visible; animation-delay: 0.2s;">
                    <img  src="assets/img/sandhya-kulkarni.jpg" alt="image">
                </div>
            </div>
            <div data-wow-delay=".3s" class="col-lg-8 col-md-12 wow fadeInLeft" style="visibility: visible; animation-delay: 0.3s;">
                <div  class="app-download-content">
                    <span class="sub-title" style="font-size: 20px !important;">Dr. Sandhya Kulkarni</span>
                    <h5 style="color: #1e64a1 !important;">Educational Qualification : M.Sc. -Biophysics, BMRIT.</h5>
                    <p >Dr Kulkarni has 30+ years of experience as a Teacher of Physiology at Nair Hospital, Mumbai. She is the Associate Director NHCDC and Chairman NHIER. And is responsible for final decisions made regarding policies procedures as well as the smooth functioning of NHI</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div class="container"><div class="border-b"></div></div>
<section class="features-area pt-5 pb-5" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div  data-wow-delay=".2s" class="app-download-image wow fadeInLeft" style="visibility: visible; animation-delay: 0.2s;">
                    <img  src="assets/img/dr-barkha-chawla.jpg" alt="image">
                </div>
            </div>
            <div data-wow-delay=".3s" class="col-lg-8 col-md-12 wow fadeInLeft" style="visibility: visible; animation-delay: 0.3s;">
                <div  class="app-download-content">
                    <span class="sub-title" style="font-size: 20px !important;">Dr. Barkha Chawla</span>
                    <h5 style="color: #1e64a1 !important;">DCH,DNB (Pediatrics) Consultant - Developmental Pediatrician.</h5>
                    <p >New Horizons Child Development Centre since October 2018 Ongoing post graduate diploma training in Developmental Neurology at Child Developmental Centre- Trivandrum.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<div class="container"><div class="border-b"></div></div>
<section class="features-area pt-5 pb-5" style="background-image: url(../../../../assets/img/services-bg.png);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div  data-wow-delay=".2s" class="app-download-image wow fadeInLeft" style="visibility: visible; animation-delay: 0.2s;">
                    <img  src="assets/img/alaknanda-sengupta.jpg" alt="image">
                </div>
            </div>
            <div data-wow-delay=".3s" class="col-lg-8 col-md-12 wow fadeInLeft" style="visibility: visible; animation-delay: 0.3s;">
                <div  class="app-download-content">
                    <span class="sub-title" style="font-size: 20px !important;">Ms. Alaknanda Sengupta</span>
                    <h5 style="color: #1e64a1 !important;">Post Graduate Diploma in Special Education, from ADAPT</h5>
                    <p>Alaknanda Sengupta has been working in the field of Special Education for the last 15 years, more than 10 of them with New Horizons Child Development Centre. She is also faculty at New Horizons Institute of Education. She specializes in helping families and children with ADHD, Learning Disabilities, Autism, Intellectual Disabilities and behavioural and emotional problems deal with their learning.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>

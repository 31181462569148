<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Testimonials</h2>
        </div>
    </div>
</div>
<section>
    <div class="container-xl mt-5 mb-2">
        <div class="row">
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/5J-4C00Yewo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4  mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/_1fmAo5rKW4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4  mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/IDWS3chV_io" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/erXNHSpRyBA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/mNddKkWwRvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/8XuBxB3avnM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/9qsi8ZNIh6M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/HlcUvgJo9eI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
            <div class="col-lg-6 col-md-4 mb-25">
                <iframe class="yt-video" src="https://www.youtube.com/embed/HlcUvgJo9eI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h6 class="news-title text-center">
                    A Happy Parent's Validation | Success Story | New Horizons Developmental Program (NHDP)
                </h6>
            </div>
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>
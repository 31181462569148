import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ChattingComponent } from './components/common/chatting/chatting.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ClinicalPracticeGuidelineComponent } from './components/pages/clinical-practice-guideline/clinical-practice-guideline.component';
import { IdentificationEvaluationManagementComponent } from './components/pages/identification-evaluation-management/identification-evaluation-management.component';
import { ShiftToOnlineTeachingDuringComponent } from './components/pages/shift-to-online-teaching-during/shift-to-online-teaching-during.component';
import { DrSamirHdalwaiDrManishGargComponent } from './components/pages/dr-samir-hdalwai-dr-manish-garg/dr-samir-hdalwai-dr-manish-garg.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { AutismComponent } from './components/pages/autism/autism.component';
import { AadComponent } from './components/pages/aad/aad.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    HomeTwoComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    ErrorComponent,
    ChattingComponent,
    ServicesComponent,
    GalleryComponent,
    BlogComponent,
    ClinicalPracticeGuidelineComponent,
    IdentificationEvaluationManagementComponent,
    ShiftToOnlineTeachingDuringComponent,
    DrSamirHdalwaiDrManishGargComponent,
    TestimonialsComponent,
    TermsAndConditionsComponent,
    AutismComponent,
    AadComponent,
    PrivacyPolicyComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

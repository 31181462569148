import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';

@Component({
  selector: 'app-aad',
  templateUrl: './aad.component.html',
  styleUrls: ['./aad.component.scss']
})
export class AadComponent implements OnInit {

  constructor(private title:Title, private meta:Meta) { }

  ngOnInit() {
    this.title.setTitle('ASD Treatment | New Horizons Child Development Center, Mumbai');
    
    this.meta.updateTag({ name: 'keywords', content: 'New Horizons Child Development Centre, Autism, Autism Spectrum Disorder, ADHD, Speech Therapy, Downs Syndrome, Cerebral Palsy, Child Development, Developmental Pediatrician, Dyslexia, Learning Disability’, Language Disorder, Dr Samir Dalwai, Child Therapy, Child Care, Child Therapy, Child Growth, Paediatrician, Language Disorder, Neuto Motor Impairment, Disorders, Child Counselling, New Horizons, CDC, Special Child, Disabled Children, Disabled Child.' });
		
    this.meta.updateTag({ name: 'description', content: 'Autism Spectrum Disorder (ASD) is a neurodevelopmental disorder that typically presents before age 3. Symptoms may vary & can improve over time with treatment.' });
  }

}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About NHD</h2>
        </div>
    </div>
</div>
<section class="features-card-section pt-70 pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp text-justify" data-wow-delay=".1s" style="margin-top: 50px;">
                <p>New Horizons Child Development Centre, introduced for the first time in India the concept and practice of self-funded inter-disciplinary, measurable and outcome based rehabilitation where each child gets individual evaluation and intervention in a comprehensive, scientifically planned and documented manner. NHD is one of the largest multi-disciplinary, comprehensive and integrated child development centres in India. Founded in 2003, with currently 5 centres across Mumbai, NHD offers developmental services under one roof through a inter-disciplinary team headed by a Developmental Paediatrician.</p>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp mb-3 text-justify" data-wow-delay=".1s">
                <div class="about-image">
                    <img src="../../../../assets/img/home-vactor.jpg" alt="image">
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="features-card-section pt-5 pb-20 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                <p>NHD provides services to 300 children daily and conducts 73,000 intervention sessions every year with a ratio of one therapist to one child in each intervention session. It has achieved a record of 73,747 intervention sessions in the year 2015 alone.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".3s">
                <p>At NHD, each child gets individual evaluation and intervention in a comprehensive, scientifically planned and documented manner. Each evaluated child gets a STRENGTHS and CHALLENGES report rather than just a diagnosis and an INDIVIDUALISED THERAPY/EDUCATION PROGRAM for 6 months developed under the NEW HORIZONS DEVELOPMENTAL PROGRAM (NHDP). Each child is re-evaluated after 6 months of continuous,regular intervention to measure development of skills as per the goals set and revised IT/EP , if needed.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".4s">
                <p>Under NHDP, every child’s parents, family and the school is counselled every month as part of the Holistic Program to maximize the impact for the child. Thus, each child has a complete end-to-end solution in a measurable, outcome based manner for his/ her concerns under one roof, under the direct observation and care of a Developmental Paediatrician.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".5s">
                <p>Following points are the USP of New Horizons Developmental Programs:</p>
                <ul class="serv-list">
                    <li>Accountability: Measurable, Outcome Oriented, Time Bound Programs.</li>
                    <li>Scientific, Multi-disciplinary Integrated Approach: Easy to Implement 6 Step Model.</li>
                    <li>Documentation: OPD to Review Stage and across all the Departments.</li>
                    <li>Monitoring: Review and Parental Feedback</li>
                    <li>Coordination (Clinical Meetings), Micro Planning.</li>
                    <li>Training and Mentoring: Inter-disciplinary Professionals and Parents.</li>
                    <li>NHD propagated the concept that “EVERY CHILD CAN DO BETTER!” with a “ FROM LABEL TO ENABLE” approach.</li>
                </ul>
            </div>            
        </div>
    </div>
</section>
<!-- <section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">

            </div>
            <div class="col-lg-12 col-md-12">

            </div>
         </div>        
    </div>
</section> -->
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_1.gif" class="img-fluid"></div>
<!-- 
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section> -->
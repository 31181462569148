<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            
        </div>
    </div>
</div> -->


<section class="faq-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <h3>Shift To Online Teaching During Covid-19 – Early Challenges And Immediate Measures Undertaken By Teachers In India</h3>
                <p>Manish Rammohan Garg1, Deepti Kanade-Modak2, Sandhya Kulkarni3, Kamini Prakash Rege4, Samir Hasan Dalwai4</p>
                <p>Section On Developmental And Behavioral Pediatrics. 2020;145(1):e20193447</p>
                <p><strong>Author’s Affiliation:</strong></p>
                <ol>
                    <li>New Horizons Child Development Centre, Mumbai, Maharashtra, India</li>
                    <li>New Horizons Health and Research Foundation, Maharashtra, India</li>
                    <li>New Horizons Institute of Education and Research, Mumbai, India</li>
                    <li>Department of Human Development, College of Home Science Nirmala Niketan, University of Mumbai, Maharashtra, India</li>
                </ol>

                <p><strong>Correspondence:</strong></p>
                <p>Manish Rammohan Garg, Email: manish_rgarg@yahoo.co.in</p>

                <p>Received on: 10-Nov-2020</p>
                <p>Accepted for Publication: 27-Dec-2020</p>

                <h3><strong>Abstract</strong></h3>
                <p><strong>Introduction</strong></p>
                <p>Schools across India in March 2020 had to suddenly switch to online teaching due to unprecedented COVID-19 pandemic lockdown. This transition led to multiple challenges for teachers.</p>

                
                <p><strong>Objectives:</strong></p>
                <p>To determine the understanding of primary and secondary grade teachers in urban areas of India regarding the onlineteaching experience precipitated by COVID-19.</p>

                
                <p><strong>Materials and Methods:</strong></p>
                <p>An online (Google) survey comprising 19 questions was circulated to the teachers across India through school principals and coordinators from 12th – 26th May 2020.</p>
                

                <p><strong>Results:</strong></p>
                <p>The online survey was completed by 462 teachers (116 (primary) and 346 (secondary); Male (18.1%); Female (81.9%) with a mean age of 41.12 + 9.65 years. The survey showed that 87.93% and 92.48% of the primary and secondary teachers, respectively, had an experience of online teaching for < 2 months; 71.30% and 85.30% of the primary and secondary teachers, respectively, got < 7 days to prepare; 51.72% and 41.32% of the primary and secondary teachers, respectively, mentioned that they enjoyed the online teaching method; To manage their apprehensions, commonly used approaches included preparation before the class, positive approach and keeping calm. The most common skills or training needs suggested were use of technology tools and online teaching techniques.</p>

                <p><strong>Conclusion:</strong></p>
                <p>Considerable efforts were taken by teachers on an urgent basis to facilitate a positive learning environment during this sudden switch to the online teaching method. The need for training on technology proficiency and effective online pedagogy techniques was highlighted.</p>

                <p><strong>Keywords:</strong></p>
                <p>COVID-19, Lockdown, Online Teaching, India, Primary and Secondary Teachers.</p>

                <h3><strong>Introduction</strong></h3>
                <p>For the third time in the last few decades, a Zoonotic Corona-Virus transcended the species barrier to infect mankind.1Acoronavirus, first detected in patients who frequented a seafood wet market in Wuhan, China, rapidly spread across the globe triggering lockdowns around the world.</p>

                <p>India swiftly closed its international borders and implemented the largest COVID-19 lockdown impacting<sup>1</sup> .3 billion people on 24<sup>th</sup> March 2020, which WHO appraised as “tough and timely” <sup>2</sup>This, led to an overnight closure of primary and secondary schools, which, consequently impacted the academic journey of students. Several schools across the country sought to manage this sudden disruption by using multiple modes of learning through a blend of technologies.</p>
                   
                <p>At the onset of the lockdown, Indian schools were in the midst of their academic year.<sup>3</sup>In the absence of government guidelines and in order to complete the curriculum, schools and teachers had to immediately switch to online teaching. Due to this unexpected transition, online teaching efforts were relatively experimental. <sup>4</sup>Both primary and secondary grade students were mostly treated on par by the faculty for online teaching approaches
                
                <p>despite their different learning needs. <sup>5</sup>When the survey was rolled out in the early days of the lockdown, teachers were homebound and engaged in online teaching as well as experimenting with and honing their online skills. In addition, they had to cope with the oncoming annual examinations as well as preparations for the new academic year.

                <p>Several studies have shown that effective online teaching is determined by carefully thought out course content, energetic exchange between the faculty and the students, well-prepared and extensively-supported faculty, generating a feel of online learning community, and keeping pace with rapid advancement of technology. <sup>6</sup>These additional skills and the creation of a new professional identity for online teaching may pose challenges to a significant number of teachers thereby impacting the children in their classroom.<sup>7</sup>, <sup>8</sup>Thus, there were multiple challenges encountered by teachers in using the virtual mode of education.

                <p>The present survey was conducted with primary and secondary grade teachers in India involved in shift to online teaching immediately after the lockdown began. The aim was to identify the early challenges and immediate approaches they implemented which could lead to development of online educational and classroom strategies ahead.</p>

                <h3><strong>Methods And Methods</strong></h3>
                <p>A Google survey comprising 19 questions was circulated among primary and secondary grade teachers through principals, school coordinators and school anagements across urban areas in India from 12<sup>th</sup> – 26<sup>th</sup>May 2020. We employed convenience sampling, to reach as many teachers in, as short time as possible, to maximize the insights. To achieve this, we shared the link on email, via WhatsApp, with our professional and personal school connects and on our official social media pages, and promoted teachers to share the link with colleagues. We posted a brief description of the purpose and scope of the study.</p>

                <div class="faq-image">
                    <img src="assets/img/shift-to-online-teaching-during-covid-19-table-1.jpg" alt="image">
                </div>

                <p>The survey questionnaire (Table 1), apart from collecting information on the demographic data and teaching experiences, targeted four general areas, of online teaching methods, namely</p>
                <ol>
                    <li>Strategies and preparedness for their implementation;</li>
                    <li>Pedagogical adaptations for the switch over;</li>
                    <li>To overcome the general challenges and approaches due to COVID-19 crisis; and
                    <li>The teachers’ views about the adaptation, engagement and the expression of positive and negative emotions during present among the students.</li>
                </ol>
                <p>The Google survey data of all the participants were auto pooled into a Microsoft excel sheet. This data was analyzed using descriptive statistics and thematic analysis. For the survey responses to open questions (Q.16, 17, 18 and 19), discussions were made by the authors with regards to the identified themes. These insights guided the structure and quantitative assessment of themes. The process is best described as iterative as discussions triggered revisiting of the analysis, and clarification of the identified themes.</p>

                <h3><strong>Results</strong></h3>
                <p>Four hundred and sixty-two teachers (116 primary grade teachers< 4<sup>th</sup> grade); and 346 secondary grade teachers from 5<sup>th</sup> – 12<sup>th</sup> grade) completed the online survey; 377 female and 83 male teachers participated (2 missing response for question on gender).The participation was of an urban pan-India nature including Vadodara (49.7%) Mumbai (18.53%), Delhi (4.6%), Dehradun (6.27%), Ludhiana (3.03%), Bhopal (0.43%), Bangalore (0.21%), Etc. There were 33.18% and 32.5% participants in the age range of 31- 40 years and 41 – 50 years, respectively; 16.2 % participants in 20 – 30 years and 51 – 60 years age range and 1.7% were above 60 years of age.</p>

                <p><strong>Previous Experience of Online Teaching</strong></p>
                <p>When online teaching was initiated by most schools in urban India in March 2020, the survey data showed that 87.93%of primary teachers and 92.48% of secondary teachers had an experience of less than 2 months of online teaching in the past. Only 15 teachers had an experience of between 2 to 6 months (3.24%) and 17 teachers had an experience of 7months - 1 year (3.67%) prior to the lockdown. [Table 2]</p>
                
                <div class="faq-image">
                    <img src="assets/img/shift-to-online-teaching-during-covid-19-table-2.jpg" alt="image">
                </div>

                <p><strong>Number of Days of Training or Preparation Received For The Switch To Online Teaching Methods:</strong></p>
                <p>Majority of the teachers [primary teachers (71.30%); secondary teachers (85.30%)] received < 7 days to prepare for the transition to online teaching. [Table 3]</p>

                <div class="faq-image">
                    <img src="assets/img/shift-to-online-teaching-during-covid-19-table-3.jpg" alt="image">
                </div>

                <p><strong>Level of Enjoyment of the Online Teaching Methods</strong></p>
                <p>51.72% primary school teachers and 41.32% secondary school teachers reported having enjoyed online teaching; however, a little more than 25% of the teachers [primary teacher (29.31%); secondary teachers (28.61%)] stated that they scarcely enjoyed online teaching. [Table 4]</p>

                <div class="faq-image">
                    <img src="assets/img/shift-to-online-teaching-during-covid-19-table-4.jpg" alt="image">
                </div>

                <p><strong>Apprehensions and Solutions</strong></p>
                <p>These were open-ended questions and the responses were analyzed using thematic analysis .A majority of the teachers used- preparation as a coping methodology for the transition to online teaching; others used positive approach, keeping calm, meditation / time management, practice, demo/ discussion with colleagues. A significant number attempted to make their sessions interactive and to up-skill themselves on technology. [Table5]</p>   
                
                <div class="faq-image">
                    <img src="assets/img/shift-to-online-teaching-during-covid-19-table-5.jpg" alt="image">
                </div>

                <p>We can teach many students together; with changing times, we need to incorporate new teaching styles and keep on learning new methods to teach. Etc.</p>
                
                <p><strong>Skills and Training Required To Update and Support Online Teaching Sessions</strong></p>
                <p>The most common skills or training needs suggested by both primary and secondary grade teachers for themselves were use of technology tools and online teaching techniques. The other skills highlighted by teachers as training needs were alertness and quick thinking, listening skills, patience, confidence, voice modulation, engagement, communication, planning, time management, interaction and setting rules. [Table 6]</p> 
                
                <div class="faq-image">
                    <img src="assets/img/shift-to-online-teaching-during-covid-19-table-6.jpg" alt="image">
                </div>

                <h3><strong>Discussion</strong></h3>
                <p>India has a unique place in the global education sector as it has around 1.4 million schools with more than 227 million registered students.               8Due to the sudden COVID-19 lockdown in India, several schools in the urban India had to make an overnight transition to online classes to help students  continue their education without attending school in person. As teachers play a critical role in the successful implementation of pedagogical strategies, the
                challenges they face in making this transition from traditional “face-to-face” classrooms to the online teaching mode will be crucial to this “new” phase in education.<sup>9-11</sup> </p>
                
                <p>In our survey, the majority of primary and secondary teachers had minimal online teaching experience previous to the lockdown (< 2 months) and received < 7 days of intimation to switch to online mode. Despite the challenges, schoolteachers appear to have largely accepted this daunting task and managed their initial hesitation well. Teachers with minimal training and expertise of technology, and those apprehensive of social media, gradually transformed themselves to enhance their skills in conducting online classes, recording and editing videos. A school principal cited that the transition was a daunting task as unlike a physical model where there are infrastructure and technology teams to bridge the gap and challenges, online teaching offered challenges as all teachers did not have access to laptops and they had to use mobile phones for teaching in the absence of real time support form technical team or formal training.</p>
                
                <p>It has been well documented that teachers’ adaptability is a key factor in effective teaching and learning and, moreover, impacts students’ academic achievement. This adaptability is shaped by individual teachers’ attributes</p>
                
                <p>(e.g., years of experience), personal elements (e.g., personality, motivation, self-efficacy, competence) and institutional dynamics (e.g., relatedness, resources, school culture, job role and tasks).12 In our study, half of the primary teachers (51.72%) and nearly half of the secondary teachers (41.32%) reported that they enjoyed the online teaching method. However, it is worth mentioning that about 25% of the teachers [primary teacher (29.31%); secondary teachers (28.61%)] stated that they scarcely enjoyed online teaching. These results are consistent with an online survey of 8,632 teachers in China, which showed that online teaching was regarded as “somewhat difficult” by 53.8% of teachers and “extremely difficult” by 6.7% of teachers; 70.5% of teachers supported the adoption of online teaching, while 25.7% of teachers were moderately opposed to online teaching.13Similar to our study, a majority of teachers displayed a high degree of psychological adaptation in the transition to a new pedagogical model, swiftly adjusting their mindsets and proactively facing the challenges</p>
                
                <p>Although the responses to online teaching in the present study looks positive, apprehension mentioned by substantial proportion of teachers is significant. Coping strategies included preparation before class / better lesson planning, positive approach, keeping calm, meditating / time management, practice, demonstrate / discuss with colleagues. A significant number of teachers tried to make their sessions interactive and refine them on the technology front. Thus, a significant diversity in the response to managing apprehension was noted due to the wide spectrum of grade and age of the children and the teachers.</p>

                <p>The two most common skills and training needs desired by both primary and secondary grade teachers were technology proficiency in teaching and effective online pedagogy for student-centred learning through active learning activities. These needs were focused and specific, unlike the spectrum of coping measures for apprehension. These training needs mirror the outcomes of studies from China and Sweden. In the former, teachers exhibited the greatest need for support in general information technology literacy, online teaching skills, online teaching resources, and home–school cooperation. The Swedish study showed that online teaching preparedness was mainly related to technical aspects, and that teachers lacked pedagogical strategies needed in the emerging learning landscape of distance education.<sup>14</sup></p>

                <p>The present study had limitations as in-person detailed interviews could not be done and an online survey mode was used. Also, the findings reflect data from private urban schools in India and have minimal representation from public schools in rural and tribal areas. Despite these limitations, the findings of this study show the willingness of teachers to adapt to online teaching in spite of the challenges and will help school managements in preparing robust online education strategies and policies in developing nations.</p>

                <h3><strong>Conclusion</strong></h3>
                <p>In the sudden corona-virus-induced national lockdown, switching to the online platform was the need of the hour and despite challenges, both primary and secondary grade teachers adapted to it well. This transition necessitates that both the teachers and the schools need to make a considerable investment in terms of time, effort and commitment to provide sufficient training for teachers, to sustain or achieve an adequate level ofcompetence, pedagogical understanding and teaching effectiveness.</p>

                <ol>
                <li>Perlman S. Another Decade, Another Coronavirus. New England Journal of Medicine 2020;382:760-62.</li>
                <li>Editorial. India under COVID-19 lockdown. Lancet 2020; 395(10233):315.</li>
                <li>Khanapurkar R, Bhorkar S, Dandare K,Kathole P. Strengthening the Online Education Ecosystem in India. ORF Occasional Paper No. 282, November 2020, Observer Research Foundation.</li>
                <li>Onyema EM, Eucheria NC, Obafemi FA, Sen S, Atonye FG, Sharma A, et al. Impact of Coronavirus Pandemic on Education. Journal of Education and Practice. 2020; 11 (13), 108-19.</li>
                <li>Romanelli F, Bird E, Ryan M. Learning styles: a review of theory, application, and best practices. Am J Pharm Educ. 2009 Feb 19;73(1):9.</li>
                <li>Sun A & Chen X. Online education and its effective practice: A research review. Journal of Information Technology Education: Research, 2016; 15, 157-190.</li>
                <li>Herma J, Virginie M, Joke V. Teacher educators' professional identity under construction: The transition from teaching faceto-face to a blended curriculum. Teaching and Teacher Education. 2018; 71. 120-33.</li>
                <li>Cross T & Polk L. Burn bright, not out: tips for managing Online teaching. Journal of educators online 2018.</li>
                <li> Jijun Y, Jialong R, Tao J, &Changqian X. What Role Should Teachers Play in Online Teaching during the COVID-19 Pandemic? Evidence from China. Science Insights Education Frontiers 2020; 5(2): 517-24.</li>
                <li>Anna CQ. Learning to teach online or learning to become an online teacher: an exploration of teachers’ experiences in a blended learning course. ReCALL 2011; 23(03): 218–32.
                <li>Khan IA, & Jahan A. Teacher educators’ need of e-training in India. Review of Research. 2018;7(10): 1-10.</li>
                <li>Collie RJ & Martin AJ. Adaptability: An important capacity for effective teachers. Educational Practice and Theory 2016; 38(1): 27-39.</li>
                <li>Song H, Wu J, &Zhi T. Results of Survey on Online Teaching for Elementary and Secondary Schools During COVID 19 Prevention and Control. ECNU Review of Education 2020; 1-10. Bergdahl N & Nouri J. Covid-19 and crisis-prompted distance education in Sweden. 2020.</li>
                </ol>

            </div>
        
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identification-evaluation-management',
  templateUrl: './identification-evaluation-management.component.html',
  styleUrls: ['./identification-evaluation-management.component.scss']
})
export class IdentificationEvaluationManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
        </div>
    </div>
</div>
<section class="features-card-section pt-5 pb-20 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                <h4><strong>This Privacy Policy applies to <a href="https://newhorizonscdc.in/">www.newhorizonscdc.in</a></strong></h4>
                <p><a href="https://newhorizonscdc.in/">www.newhorizonscdc.in</a> recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on <a href="https://newhorizonscdc.in/">www.newhorizonscdc.in</a> and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.
                </p>
                <p>
                    <a href="https://newhorizonscdc.in/">www.newhorizonscdc.in</a> is a property of New Horizons Child Development Centre having its registered office at Saira Mansion, 3 rd Floor, Goregaon East, Mumbai-400063.
                </p>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Personal Identification Information</h4>
                    <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site place an order fill out a form subscribe to the newsletter and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, credit card information, Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if theyvoluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Non-personal Identification Information</h4>
                    <p>information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilised and other similar information.</p>
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Web Browser Cookies</h4>
                    <p>Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>How We Use Collected Information</h4>
                    <p>New Horizons Child Development Centre collects and uses Users personal information for the following purposes:</p>
                    <p><i>To improve customer service</i></p>
                    <p>Your information helps us to more effectively respond to your customer service requests and support needs.</p>

                    <p><i>To improve customer service</i></p>
                    <p>Your information helps us to more effectively respond to your customer service requests and support needs.</p>

                    <p><i>To improve our Site</i></p>
                    <p>We continually strive to improve our website offerings based on the information and feedback we receive from you.</p>

                    <p><i>To process transactions</i></p>
                    <p>We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</p>
                    <p>i.e. – SMS services, Email services etc..</p>

                    <p><strong>To administer a content, promotion, survey or other Site feature</strong></p>
                    <p><strong>To send Users information they agreed to receive about topics we think will be of interest to them.</strong></p>
                    <p><strong>To send periodic emails</strong></p>
                    <p>The email address Users provide for payment/order processing, will only be used to send them information and updates pertaining to their request.
                        It may also be used to respond to their inquiries, and/or other requests or questions. We send awareness emails on special events with purpose of education, If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site.
                    </p>
                    
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>How We Protect Your Information</h4>
                    <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
                    <p>Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.
                    </p>
                    
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Sharing Your Personal Information</h4>
                    <p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not
                        linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.
                    </p>
                    
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Changes To This Privacy Policy</h4>
                    <p>New Horizons has the discretion to update this privacy policy at any time. When we do, revise the updated date at the bottom of this page, We
                        encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                    </p>
                    
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Your Acceptance Of These Terms</h4>
                    <p>By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site.Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
                    </p>
                    
                </div>
            </div>
            <div class="row">
                
                <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                    <h4>Contacting Us</h4>
                    <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
                    <p>New Horizons Child Development Centre</p>
                    <p>www.newhorizonscdc.in</p>
                    <p>33, Saira Mansion, Goregaon East, Mumbai, India – 400063</p>
                    <p>Info@enablemychild.org</p>
                    <p>Policy Revision Date – April 7, 2021</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_1.gif" class="img-fluid"></div>

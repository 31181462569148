import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthenticationService } from "../../../_services/authentication.service";
import { User } from "../../../_models/user";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {
    public userDetails: any;
    currentUser: User;
    location: any;
    navbarClass: any;

    constructor( private router: Router, location: Location, private authenticationService: AuthenticationService ) {
        
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/' || this.location == '/home-2' || this.location == '/home-3' || this.location == '/home-5' || this.location == '/home-6' || this.location == '/home-7' || this.location == '/without-animation-home-1' || this.location == '/without-animation-home-2' || this.location == '/without-animation-home-3' || this.location == '/without-animation-home-5' || this.location == '/without-animation-home-6' || this.location == '/without-animation-home-7'){
                    this.navbarClass = 'navbar-area';
                } else if (this.location == '/home-4' || this.location == '/without-animation-home-4'){
                    this.navbarClass = 'navbar-area bg-white';
                } else {
                    this.navbarClass = 'navbar-area navbar-style-two';
                }
            }
        });
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit() {
        this.userDetails = this.currentUser;
    }

    logout() {
        localStorage.removeItem("userData");
        localStorage.removeItem("diy_api_tokne");
        this.authenticationService.logout();
        this.router.navigate([""]);
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      }

}
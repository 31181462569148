<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms &amp; Conditions</h2>
        </div>
    </div>
</div>
<section class="features-card-section pt-5 pb-20 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <h4>Terms of Use</h4>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                <p><strong>Horizons Child Development Centre</strong> welcomes you to use our Online Payment Facility for payment of Centre Services.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".3s">
                <p>This link will be shared with you by our team via SMS.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".4s">
                <p>The facility will be available for online payment through UPI, Credit Card, Debit Card and Net Banking only.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".5s">
                <p>You represent and warrant that you are competent and eligible to enter into a legally binding contract. You shall not use this section if you are not competent to contract under the applicable laws.</p>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".6s">
            <p>
                When making any online payment(s) through your credit card/ net banking account(s), you will be redirected to the Payment Gateway page. The Payment Gateway will redirect you to other website(s) maintained or controlled by third party (ies) (herein after referred as &quot;Third PartyWebsite(s)&quot;). <strong>Horizons Child Development Centre</strong> does not control the any Third PartyWebsite(s) and is not responsible for any transactions on the any Third PartyWebsite(s). N<strong>Horizons Child Development Centre</strong> is not responsible for, shall have no liability for and disclaims all warranties whatsoever expressed or implied related to the Third PartyWebsite(s), including without limitation any warranties related to performance, security, stability, or non-infringement of the title of the Third PartyWebsite(s) and website content or any controls downloaded from the Third PartyWebsite(s).  
            </p>
            </div>   
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <p>
                    <strong>Horizons Child Development Centre</strong> shall not be responsible for any issues pertaining to the online payment(s) made by you through your credit card/ net banking account(s) including but not limited to, excess charges charged by the Bank/credit card or net banking account supplier, double debit to the credit card account, invalid card details, authentication of card details and ancillary/ incidental charges for the transaction. You are requested to communicate all grievances related to such issues to Your Bank/ credit card or net banking account supplier. <strong>Horizons Child Development Centre</strong> helpline will not entertain or address any such grievances or issues.
                </p>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".8s">
                <p>
                    If the Bank/ credit card or net banking account supplier declines payment, New Horizons Child Development Centre shall be under no obligation to bring this fact to your attention. Customers should check with their Bank/ credit card or net banking account supplier, that payment has been deducted from their account.
                </p>
            </div>    
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".9s">
                <p>
                    You agree, understand and confirm that the credit card/ net banking account details provided by you for availing of services will be correct and accurate and you shall not use the credit card/net banking account which is not lawfully owned by you
                </p>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".10s">
                <p>
                    The Centre shall not in any way be liable for any delay, disruption or defects in the Online Payment Facility
                </p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".11s">
                <p>
                    Any dispute arising out of such use of the facility will be governed by the laws of India and subject to the jurisdiction of the Courts at Mumbai
                </p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".12s">
                <p>
                    <strong>Horizons Child Development Centre</strong> does not warrant or makes any representations regarding the use or the results of the Service in terms of its compatibility, correctness, accuracy, reliability or otherwise. You assume total responsibility and risk for your access and use of the Service.
                </p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".13s">
                <p>
                    The Centre may at any time modify the Terms and Conditions at its discretion without any prior notice to the remitter/patient. Such Modified Terms and Conditions shall be applicable with immediate effect.
                </p>
            </div>
        </div>
        <div class="row">
            <h4>Cancellation / Refund Policy</h4>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                <p>If any payment is due to be refunded, will be initiated only after the same is duly approved by the respective authority of the Centre to issue the refund in accordance with the applicable Centre Policy. By issuing cheque only.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".3s">
                <p>All bank processing charges of bank/ software vendor in the event of refund due to cancellation of consultation by patient will be deducted from the refund of the payee.</p>
            </div>
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_1.gif" class="img-fluid"></div>

import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';

@Component({
  selector: 'app-autism',
  templateUrl: './autism.component.html',
  styleUrls: ['./autism.component.scss']
})
export class AutismComponent implements OnInit {

  constructor(private title:Title, private meta:Meta) { }

  ngOnInit() {
    this.title.setTitle('Autism Treatment | New Horizons Child Development Center, Mumbai');
    
    this.meta.updateTag({ name: 'keywords', content: 'New Horizons Child Development Centre, Autism, Autism Spectrum Disorder, ADHD, Speech Therapy, Downs Syndrome, Cerebral Palsy, Child Development, Developmental Pediatrician, Dyslexia, Learning Disability’, Language Disorder, Dr Samir Dalwai, Child Therapy, Child Care, Child Therapy, Child Growth, Paediatrician, Language Disorder, Neuto Motor Impairment, Disorders, Child Counselling, New Horizons, CDC, Special Child, Disabled Children, Disabled Child.' });
		
    this.meta.updateTag({ name: 'description', content: 'Children with ADHD have trouble paying attention, controlling impulsive behaviors, or are hyperactive with poor sitting tolerance & inability to complete tasks.' });
  }


}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <!-- <p>Drop us Message for any Query</p> -->
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="">
            <div class="row">
                <div class="col-lg-6 col-md-12 mb-5">
                    <div class="contact-features-list contact-inner">
                        <h3>Centre Locations:</h3>
                        <ul>
                            <li class="center-location"><i class="bx bx-map icn"></i> <span class="color-f2757d">Goregaon East</span>
                                <p class="address">Saira Mansion, Near, Pahadi School Road Number 2, Peru Baug, Jay Prakash Nagar, Goregaon, Mumbai, Maharashtra 400066</p>
                                <p class="c-location"> Contact details- 022-23270685/+91 9819162191</p>
                                <p class="c-location"><a href="https://api.whatsapp.com/send?phone=919819162191&text=Hello&source=&data=" target="_blank"><img src="./../../../../assets/img/whatsapp.png" style="width: 25px;"> 9819162191</a></p>
                                <p class="get-directions"><a href="https://g.co/kgs/nAae1p" class="btn-safety-orange" target="_blank">Get Directions</a></p>
                            </li>
                            
                            
                            <li class="center-location"><i class="bx bx-map icn"></i><span class="color-f2757d">Khar</span>
                                <p class="address">1st Floor, Solanki Palace, 5th Rd, Khar, Jay Bharat C H S, Khar West, Mumbai, Maharashtra 400052</p>
                                <p class="c-location"> Contact details- +91 8452940605/+91 7045352552</p>
                                <p class="c-location"><a href="https://api.whatsapp.com/send?phone=917045352552&text=Hello&source=&data=" target="_blank"><img src="./../../../../assets/img/whatsapp.png" style="width: 25px;"> 7045352552</a></p>
                                <p class="get-directions"><a href="https://g.co/kgs/tP9X3N" class="btn-safety-orange" target="_blank">Get Directions</a></p>
                            </li>
                            
                            <li class="center-location"><i class="bx bx-map icn"></i> <span class="color-f2757d"> Bhandup</span> 
                                <p class="address">Pawar Public School, Opp. Dreams Mall, Off, Lal Bahadur Shastri Rd, Bhandup West, Mumbai, Maharashtra 400078</p>
                                <p class="c-location"> Contact details- +91 91526 26181/+91 97696 69332</p>
                                <p class="c-location"><a href="https://api.whatsapp.com/send?phone=919769669332&text=Hello&source=&data=" target="_blank"><img src="./../../../../assets/img/whatsapp.png" style="width: 25px;"> 9769669332</a></p>
                                <p class="get-directions"><a href="https://g.co/kgs/a8GW6H " class="btn-safety-orange" target="_blank">Get Directions</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list contact-inner">
                        <h3></h3>
                        <ul style="font-size: 16px;">
                            <li class="center-location"><i class="bx bx-map icn"></i><span class="color-f2757d">Goregaon West</span>
                                <p class="address">2nd Floor,Techniplex II, Techniplex Complex, Veer Savarkar Flyover, Goregaon West, Mumbai, Maharashtra 400062</p>
                                <p class="c-location"> Contact details- 022-6689333/+91 75061 71240</p>
                                <p class="c-location"><a href="https://api.whatsapp.com/send?phone=917506171240&text=Hello&source=&data=" target="_blank"><img src="./../../../../assets/img/whatsapp.png" style="width: 25px;"> 7506171240</a></p>
                                <p class="get-directions"><a href="https://g.co/kgs/7itxvy" class="btn-safety-orange" target="_blank">Get Directions</a></p>
                            </li>
                            <li class="center-location"><i class="bx bx-map icn"></i><span class="color-f2757d">Dadar</span>
                                <p class="address">Sai Kunj Building, 1st Floor, Jeevak Nursing Home, MMGS Marg, Dadar East, Mumbai, Maharashtra 400014</p>
                                <p class="c-location"> Contact details- 022 2414 4596/+91 75061 71230</p>
                                <p class="c-location"><a href="https://api.whatsapp.com/send?phone=917506171230&text=Hello&source=&data=" target="_blank"><img src="./../../../../assets/img/whatsapp.png" style="width: 25px;"> 7506171230</a></p>
                                <p class="get-directions"><a href="https://g.co/kgs/8kYkFc" class="btn-safety-orange" target="_blank">Get Directions</a></p>
                            </li>

                            <!-- <li><i  class="bx bx-map icn" ></i> 102, Solanki Palace, 3rd & 5th road Junction, opposite Hanuman mandir, Khar (W), Mumbai 400052</li>
                            <li> <i class="bx bx-mobile icn" style="background: #afc637 !important;"></i> 07045352552, 08452940605  <br></li>
                            <li><i class="bx bx-conversation icn" style="background: #f2757d !important; "></i> info@enablemychild.org</li> -->
                        </ul>
                        <div class="section-title mt-50">
                            <h2 class="missions-h2" style="padding-bottom:10px;  color: #f2757d !IMPORTANT;">ONLINE SERVICES</h2>
                            <h2 class="missions-h3">+91 9819224415</h2>
                        </div>
                    </div>
                    <!--  <div class="contact-form">
                        <h3>Let's Start Your Free Trial</h3>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn"><i class='bx bxs-paper-plane'></i>Send Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>-->
                </div>
            </div>
            <!-- <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contact us by Phone Number or Email Address</h3>
                    <h2>
                        <a href="tel:+0881306298615">+919819162191</a>
                        <span>OR</span>
                        <a href="mailto:info@enablemychild.org">info@enablemychild.org</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>

<section class="container-fluid chat-bg m-0 ">
    <div class="container p-0 ">
        <h3 class="text-center mb-4 text-black">WELCOME TO DIY.HEALTH</h3>
        <div class="chat-wrapper">
            <div class="chat">
                <div class="chat-container">
                    <div class="chat-listcontainer">
                        <ul class="">
                            <li class="message-right ms1" custom-hidden="" style="display: list-item;">
                                <div class="sp-ms1" style="display: none;"><span class="spinme-right"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></span></div>
                                <div class="messageinner-ms1" custom-hidden="" style="display: block;"><img src="assets/img/author-image/img8.jpg"><span class="message-text">👋 I'm a Jexsa. Let me know if you have any questions regarding our tool or set up a demo to learn more!<span class="message-time">19:58</span></span>
                                </div>
                            </li>
                            <li class="message-left ms2" custom-hidden="" style="display: list-item;">
                                <div class="sp-ms2" style="display: none;"><span class="spinme-left"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></span></div>
                                <div class="messageinner-ms2" custom-hidden="" style="display: block;"><img src="assets/img/author-image/img7.jpg"><span class="message-text">Ask a Question<span class="message-time">19:58</span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
       </div>
       </section>


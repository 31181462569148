<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" href="/">
                <img src="assets/img/logo.jpg" alt="logo" style="width: 230px !important;">
            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                
                <ul class="navbar-nav">
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About NHD</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/gallery-resource-centre" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Gallery & Resource</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"><a routerLink="/contact-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>

                    
                    
                    <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->
                </ul>
                <div class="others-options">
                       
                </div>
            </div>
        </nav>
    </div>
</div>

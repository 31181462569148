<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            
        </div>
    </div>
</div> -->


<section class="faq-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <h3>Clinical Practice Guideline for the Diagnosis, Evaluation, and Treatment of Attention Deficit/Hyperactivity Disorder in Children and Adolescents</h3>
                <p><strong>Pediatrics. 2019;144(4):E20192528.</strong></p>
                <p><strong>Background and Objectives:</strong>  ADHD is the most common neurobehavioral disorder of childhood with a median age of diagnosis of 7 years, one third diagnosed before 6 years. As symptoms continue in adolescence and adulthood, the hyperactive and impulsive symptoms decline and inattentive symptoms persist, profoundly affecting the social interaction, academic achievement and well-being.</p>
                <p><strong>Methodology:</strong>  The American Academy of Pediatrics (AAP) in collaboration with several organizations, set up a subcommittee working through 2015-2018 and reviewed practice changes and newly identified issues to publish these guidelines</p>
                <div class="faq-image">
                    <img src="assets/img/clinical-practice-guideline-for-the-diagnosis-1.jpg" alt="image">
                </div>

                <p><strong>Results:</strong> Diagnosis – Any child or adolescent (4 -18 years) presenting with academic or behavioral problems and symptoms of inattention, hyperactivity, or impulsivity should be evaluated for ADHD using DSM-5 criteria for core symptoms. Impairment should be documented in more than 1 major setting (i.e. social, academic, or occupational) and alternative causes ruled out. For an adolescent, manifestations of ADHD should be present from before 12 years of age and mimickers or comorbid condition, such as substance use (eg. marijuana), depression, and/or anxiety, trauma experiences, posttraumatic stress disorder, and toxic stress should be considered as well.</p>
                <div class="faq-image">
                    <img src="assets/img/clinical-practice-guideline-for-the-diagnosis-2.jpg" alt="image">
                </div>
                <p><strong>Medication:</strong> For preschoolers, methylphenidate has moderate evidence for efficacy and safety; other stimulants and nonstimulants have not been studied. In 6-12 years and 12-18 years age groups, evidence for stimulants* is the strongest followed by nonstimulants (Atomoxetine, extended release guanafacine and clonidine).Careful choice of medication to be done, after proper evaluation. </p>

                <p><strong>Behavioral therapy:</strong> training in behavioral management (PTBM) and/or behavioral classroom interventions have a strong evidence in preschoolers with ADHD and those with problem behaviors not meeting ADHD criteria.</p>

                <p><strong>Conclusion:</strong> Behavioral therapy is the first line of management in preschool years, even in absence of meeting the full diagnostic criteria for ADHD. Medications are preferred in children above 6 years; combined therapy with medications and behavioral therapy are even more effective</p>
            </div>
            <div class="col-lg-4 col-md-12 mt-25">
                <div class="faq-image">
                    <img src="assets/img/dr-parik-1.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-8 col-md-12 mt-25">
                <p><strong>“Though pharmacotherapy is the mainstay of treatment especially beyond 6 years, behavioral intervention and adherence to it, till after resolution of symptoms is critical for good outcomes at all ages. The latter is true even before assigning an ADHD diagnosis, and even in those not meeting the diagnostic criteria for ADHD wherein medications are not indicated. Where medications are indicated, “start low and increase slow”,as per the response, to avoid side effects, keeping in mind the taboo associated with medications.”</strong>
                </p>
                <p>Dr Samir Hasan Dalwai, MD, DCH, DNB, FCPS, FIAP, LLB,</p>
                <p>Developmental Behavioural Pediatrician, New Horizons Child Development Centre & Research Foundation, Nanavati and Hinduja Hospitals, Mumbai.</p>
            </div>
        </div>
        <h4 class="mt-25"><strong>With warm regards</strong></h4>
        <div class="row">
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. MANINDERS DHALIWAL<br>
                Editor – Academic Pearls<br>
                pedpearls@gmail.com</p>
            </div>
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. PIYUSH GUPTA<br>
                    IAP NATIONAL PRESIDENT 2021</p>
            </div>
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. REMESH KUMAR<br>
                    IAP PRESIDENT ELECT 2021</p>
            </div>

            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR. G.V. BASAVARAJA<br>
                    HON. SECRETARY GEN. 2021 - 22</p>
            </div>
            <div class="col-lg-3 col-md-3 mt-25">
                <p>DR BAKUL JAYANT PAREKH<br>
                    IAP PRESIDENT 2020</p>
            </div>
        </div>    
        <div>
            <p><strong>Reference</strong></p>
            <p>Wolraich ML, Hagan JF, Allan C, et al. AAP Subcommittee On Children And Adolescents With Attention-Deficit/Hyperactive Disorder. Clinical Practice Guideline For The Diagnosis, Evaluation, And Treatment Of Attention-Deficit/Hyperactivity Disorder In Children And Adolescents.</p>

            <p>Pediatrics. 2019; 144(4):E20192528. * Stimulants – Methylphenidate, Dextroamphetamine, amphetamine; Non stimulants – Atomoxetine, Guanfacine, Clonidine;</p>

        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_2.gif" class="img-fluid"></div>



<footer class="dark-footer" *ngIf="show == true">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a href="/"><img src="assets/img/logo.jpg" alt="image" style="width: 175px !important;"></a>
                    </div>
                    <p class="text-white">New Horizons Child Development Centre, introduced for the first time in India the concept and practice of self-funded inter-disciplinary, measurable and outcome based rehabilitation where each child gets individual evaluation and intervention in a comprehensive, scientifically planned and documented manner.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" >
                <div class="single-footer-widget">
                    <h3 class="text-black text-head">Quick Links</h3>
                    <div lass="footer-item">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12">
                                <ul class="footer-list">
                                    <li><a href="" class="text-white">Home</a></li>
                                    <li><a href="/about" class="text-white">About NHD</a></li>
                                    <li><a href="/services" class="text-white">Services</a></li>
                                    <li><a href="/terms-and-conditions" class="text-white">Terms & Conditions</a></li>
                                    <li><a href="/privacy-policy" class="text-white">Privacy Policy</a></li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                                <ul class="footer-list">
                                    <li><a href="/blog" class="text-white">Blog</a></li>
                                    <li><a href="/gallery-resource-centre" class="text-white">Gallery & Resource Centre</a></li>
                                    <li><a href="/contact-us" class="text-white">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="text-black text-head">Contact Info</h3>
                    <ul class="footer-contact-info">
                        <!-- <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">6th Floor, Elegant Tower, York, USA</a></li> -->
                        <li class="text-white">Email: <a href="info@enablemychild.org" class="text-white">info@enablemychild.org</a></li>
                        <li class="text-white">Phone: <a href="tel:+919819162191" class="text-white">+919819162191</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/newhorizonscdc/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/newhorizonscdc" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li> -->
                        <li><a href="https://www.instagram.com/newhorizonscdc" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area ">
            <p class="text-white">© Copyright 2023 New Horizon Child Development Center All Rights<br>Developed by  <a href="//deputybrandworks.in/" target="_blank" class="text-white">Deputy Brand Works</a></p>
        </div>
    </div>
    <a href="https://api.whatsapp.com/send?phone=919819224415 &text=Hello&source=&data=" target="_blank"><img src="./../../../../assets/img/whatsapp.png" class="wb-btn"></a>
</footer>

<!-- <div class="go-top"><i class='bx bx-chevron-up'></i></div> -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 class="main-page-head">Autism Spectrum Disorder (ASD)</h1>
        </div>
    </div>
</div>
<section class="features-card-section pt-70 pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp text-justify" data-wow-delay=".1s" style="margin-top: 30px;">
                <p>Autism spectrum disorder (ASD) is a biologically-based neurodevelopmental disorder characterized by impairments in two major domains of development: social communication and social interaction and restricted, repetitive patterns of behavior, interests, or activities with severity based on impairments and symptoms presenting when social demands exceed limited capacities.</p>
                <p>The term “spectrum” in ASD indicates that each individual is affected in different ways, with mild to severe symptoms.</p>
                <p>Autism has a genetic predisposition. In addition to that environmental factors and complications during pregnancy also play a role in triggering autism.</p>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 wow fadeInUp mb-3 text-justify" data-wow-delay=".1s">
                <div class="about-image">
                    <img src="../../../../assets/img/home-vactor.jpg" alt="image">
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="features-card-section pt-5 pb-20 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".2s">
                <p>The manner in which ASD affects a person’s functioning depends on the severity and combination of symptoms, the presence or absence of associated conditions and the
                    quality of intervention received.</p>
                <p>Autism Spectrum Disorder (ASD) is a condition that typically presents before the age of 3. Symptoms may vary and can improve over time. Some children may exhibit signs of ASD as early as the first year of life, while others may not show symptoms until later, such as at 2 years old or beyond. Some children with ASD may develop new skills and meet developmental milestones, but then stop progressing or lose previously acquired abilities.</p>    
                <p>As they grow older, individuals with ASD may have challenges forming and maintaining friendships, communicating with others, and understanding social norms in settings such as school and work. They may also have a higher likelihood of co-occurring conditions such as anxiety, depression, and ADHD.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".3s">
                <h2 class="page-hed-h5">Symptoms of ASD</h2>
                <ul>
                    <li> Limited eye contact</li>
                    <li> Inattention to verbal cues such as one's name being called</li>
                    <li> Delayed development in language and communication skills</li>
                    <li> Excessive fussiness or difficulty regulating emotions in social settings</li>
                    <li> Trouble interacting and building relationships with peers</li>
                    <li> Hyperactivity or restlessness</li>
                    <li> Short attention span</li>
                    <li> Engaging in repetitive speech or vocalizations with little meaning</li>
                    <li> Preoccupation with specific patterns or routines</li>
                    <li> Repetitive body movements, such as flapping or spinning</li>
                    <li> Behavioral challenges</li>
                    <li> Limited ability to engage in back-and-forth conversation</li>
                    <li> Limited interests or difficulty shifting attention</li>
                    <li> Struggles academically</li>
                </ul>
                <p>Please note that everyone with ASD is different and might not show all those signs, some might show more, and others less.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".4s">
                <h2 class="page-hed-h5">Diagnosis of ASD</h2>
                <p>The process of diagnosing Autism Spectrum Disorder (ASD) does not involve a specific medical test, such as a blood test. Instead, a diagnosis is based on observations of the individual's behavior and development. Early signs of ASD can be detected as early as 18 months, and a reliable diagnosis by an experienced developmental pediatrician can typically be made by age 2.</p>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".5s">
                <h2 class="page-hed-h5">Treatment of ASD</h2>
                <p>Treatment for Autism Spectrum Disorder (ASD) aims to reduce symptoms that interfere with daily functioning and quality of life. Research shows that early intervention services can greatly improve a child’s development. In order to make sure your child reaches their full potential, it is very important to receive services as soon as possible. Since ASD affects each person differently, treatment plans are tailored to the specific strengths, challenges, and needs of the individual. This often involves a team of professionals working together to create an individualized plan as part of developmental programs.</p>
            </div>   
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".6s">
                <h2 class="page-hed-h5">Developmental Programs for ASD in Mumbai at NHCDC</h2>
                <p>At <a href="https://newhorizonscdc.in" class="link-page">New Hozions Chil Development Center</a>, every child gets an assessment and diagnosis by a Developmental Pediatrician, who then outlines a customized intervention plan that is based on the child’s needs and focuses on outcomes and overall development as part of our programs.
                </p>
                <p>These programs have been developed by <a href="https://newhorizonscdc.in/team" class="link-page">Dr. Samir Dalwai & team</a> based on decades of developmental experience and research and have benefited thousands of students at our multiple <a href="https://newhorizonscdc.in/contact-us" class="link-page">child development centers in Mumbai</a> since 2003.</p>
                <p>If you are a parent or caretaker seeking specialized and comprehensive evaluation and care for your child, we would be honored to assist you. Our team of experts is dedicated to providing exceptional pediatric intervention for your child, addressing your concerns, and providing the necessary support to ensure their overall well-being through our programs.</p>
            </div>  
            <div class="col-lg-12 col-sm-12 col-md-12 wow fadeInUp mb-3 text-justify" data-wow-delay=".7s">
                <h2 class="page-hed-h5">Disclaimer</h2>
                <p>The information provided on this website is intended for general informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a qualified pediatrician or other qualified healthcare providers with any questions you may have regarding a medical condition. If you have any concerns about your child's health, please consult with a pediatrician for proper diagnosis and treatment.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="footer-gif"><img src="../../../../assets/img/gif/panel_1.gif" class="img-fluid"></div>